import { useState } from 'react';
import Link from 'next/link';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { useAuth } from '../lib/auth';
import { Public, Button, P, Meta, ErrorBanner, Divider } from '../lib/ui';
import ResetPassword from '../lib/components/reset-password';
// https://docs.amplify.aws/lib/auth/mfa/q/platform/js#sign-in-with-custom-auth-challenges
type Props = {
  className?: string;
};

type Inputs = {
  email: string;
  password: string;
};

const passwordOpts = {
  required: 'Password must be at least 6 characters long.',
  minLength: {
    value: 6,
    message: 'Password must be at least 6 characters long.',
  },
};
const emailOpts = {
  required: 'A valid email address is required.',
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'A valid email address is required.',
  },
};

export default function Login(_props: Props) {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<Inputs>();
  const [formError, setFormError] = useState(null);
  const [nextStep, setNextStep] = useState<any>()
  const router = useRouter();
  const { login } = useAuth();
  const onSubmit = async ({ email, password }, e) => {
    e.preventDefault();
    setFormError(null);
    try {
      const session = await Auth.signIn(email, password);
      if (!session.challengeName) {
        login(session);
        return router.push('/templates');
      } else if (session.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNextStep(session)
        return;
      } else if (session.challengeName === 'FORCE_CHANGE_PASSWORD') {
        setFormError('TODO handle user invite expired');
        return;
      }
      setFormError(
        'An unknown error occurred. Please try your request at a later time.'
      );
    } catch (error) {
      setFormError(error?.message || 'An unknown error occurred on login');
    }
  };
  return <>
    <Meta title="Log in to Postdrop" />
    <Public.Header />
    {nextStep?.challengeName && nextStep?.challengeName === 'NEW_PASSWORD_REQUIRED'
      ? (
        <ResetPassword nextStep={nextStep} />
      ) : (
        <Public.Body title="Log In">
          <form
            action="/api/auth/google-sign-in"
            method="GET"
          >
            <Button
              tag="button"
              type="submit"
              id="btn-login-google"
              className="w-full flex mt-8 mb-4 px-0 justify-center relative h-full">
              <div className='absolute left-1 top-1 bottom-1 bg-white flex rounded-md'>
                <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" rx="4" fill="#fff" /><path fill-rule="evenodd" clip-rule="evenodd" d="M36 20.364c0-1.135-.104-2.226-.297-3.273H20.326v6.19h8.787c-.378 2-1.529 3.694-3.258 4.828v4.015h5.277C34.219 29.338 36 25.236 36 20.364Z" fill="#4285F4" /><path fill-rule="evenodd" clip-rule="evenodd" d="M20.327 36c4.408 0 8.103-1.433 10.805-3.876l-5.277-4.015c-1.462.96-3.332 1.527-5.528 1.527-4.253 0-7.852-2.814-9.136-6.596H5.737v4.145C8.423 32.415 13.944 36 20.327 36Z" fill="#34A853" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11.191 23.04A9.444 9.444 0 0 1 10.68 20c0-1.055.186-2.08.512-3.04v-4.145H5.737a15.734 15.734 0 0 0 0 14.37l5.454-4.145Z" fill="#FBBC05" /><path fill-rule="evenodd" clip-rule="evenodd" d="M20.327 10.364c2.397 0 4.549.807 6.24 2.392l4.683-4.589C28.423 5.585 24.727 4 20.328 4c-6.383 0-11.904 3.586-14.59 8.815l5.454 4.145c1.284-3.782 4.883-6.596 9.136-6.596Z" fill="#EA4335" /></svg>

              </div>
              Login With Google
            </Button>
          </form>
          <Divider text="OR" />
          <form className="space-y-6 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  name="email"
                  id="email"
                  ref={register(emailOpts)}
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
                />
              </div>
              {errors?.email && <ErrorBanner title={errors.email.message} />}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  name="password"
                  type="password"
                  id="password"
                  ref={register(passwordOpts)}
                  autoComplete="current-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
                />
              </div>
              {errors?.password && (
                <ErrorBanner title={errors.password.message} />
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">{/* placeholder */}</div>
              <div className="text-sm align-right">
                <Link href="/forgot" className="font-medium text-blue hover:text-blue">
                  Forgot your password?
                </Link>
              </div>
            </div>
            {formError && <ErrorBanner title={formError} />}
            <div>
              <Button
                tag="button"
                type="submit"
                id="btn-login"
                disabled={isSubmitting}
                className="w-full flex justify-center">
                {isSubmitting ? 'Logging In...' : 'Log In'}
              </Button>
            </div>
          </form>
          <div className="mt-6 border-t border-gray-300 pt-4">
            <div>
              <P>
                First time here?{' '}
                <Link href="/signup" className="font-medium text-blue hover:text-blue">

                  Sign up for a Postdrop account.

                </Link>
              </P>
            </div>
          </div>
        </Public.Body>
      )
    }
  </>;
}

Login.Layout = Public;
